<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- pilih toko -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Toko"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterToko
                            v-model:value="form.vendor_id"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih program -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Program"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="program_id"
                        has-feedback>
                        <FilterProgram
                            v-model:value="form.program_id"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih oum -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Uom"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="uom_id"
                        has-feedback>
                        <FilterLoyaltyUom
                            v-model:value="form.uom_id"
                            v-model:program="form.program_id"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih reward -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Reward"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="reward_id"
                        has-feedback>
                        <FilterLoyaltyProgramReward
                            v-model:value="form.reward_id"
                            v-model:program="form.program_id"
                            v-model:uom="form.uom_id"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- quantity -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Quantity"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="quantity"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.quantity"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import FilterToko from '@/components/filter/FilterToko'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterLoyaltyUom from '@/components/filter/FilterLoyaltyUom'
import FilterLoyaltyProgramReward from '@/components/filter/FilterLoyaltyProgramReward'
import Form from 'vform'
import { message } from 'ant-design-vue'
import localStorage from 'store'

export default defineComponent({
    components: {
        FilterToko,
        FilterProgram,
        FilterLoyaltyUom,
        FilterLoyaltyProgramReward,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const user = computed(() => localStorage.get('profile'))

        const form = reactive(new Form({
            id: props.item.id,
            vendor_code: [],
            program_id: [],
            uom_id: [],
            reward_id: [],
            quantity: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Redeem Reward',
            endpoint: '/api/loyalty-reward/redeem',
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                vendor_id: [
                    {
                        required: true,
                        message: 'Toko tidak boleh kosong!',
                    },
                ],
                program_id: [
                    {
                        required: true,
                        message: 'Program tidak boleh kosong!',
                    },
                ],
                uom_id: [
                    {
                        required: true,
                        message: 'Uom tidak boleh kosong!',
                    },
                ],
                reward_id: [
                    {
                        required: true,
                        message: 'Reward tidak boleh kosong!',
                    },
                ],
                quantity: [
                    {
                        required: true,
                        message: 'Quantity tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors

                            console.log(state.errors)
                        }
                    })
            } else {
                form.put(`${state.endpoint}/${form.id}`)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.vendor_code = props.item.vendor_code
                form.program_id = props.item.program_id
                form.uom_id = props.item.uom_id
                form.reward_id = props.item.reward_id
                form.quantity = props.item.quantity
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Redeem Reward`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
