<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterProgram
                    style="width:100%;"
                    v-model:value="state.params.program"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ASelect
                    v-model:value="state.params.status"
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%;"
                    :options="state.statusList"
                />
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>
        
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ADropdown>
                        <template #overlay>
                            <AMenu @click="btnApprove">
                                <AMenuItem key="all">
                                    All Approve
                                </AMenuItem>
                                <AMenuItem key="checked">
                                    Approve
                                </AMenuItem>
                            </AMenu>
                        </template>
                        <AButton
                            :loading="state.isApprove"
                            type="primary"
                            title="Approve">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                            <DownOutlined />
                        </AButton>
                    </ADropdown>
                    <ADropdown>
                        <template #overlay>
                            <AMenu @click="btnReject">
                                <AMenuItem key="all">
                                    All Reject
                                </AMenuItem>
                                <AMenuItem key="checked">
                                    Reject
                                </AMenuItem>
                            </AMenu>
                        </template>
                        <AButton
                            :loading="state.isReject"
                            type="danger"
                            title="Reject">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/>
                            Reject
                            <DownOutlined />
                        </AButton>
                    </ADropdown>
                    <ATooltip
                        v-if="!hasRoles([ROLE_SPC])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint"
                            :params="state.params"
                            namefile="Approval-Redeem"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange, getCheckboxProps: onDisabledCheckbox }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { DownOutlined } from '@ant-design/icons-vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import { pickBy } from 'lodash'
import {
    hasRoles,
    ROLE_SPC,
} from '@/helpers'

export default defineComponent({
    components: {
        DownOutlined,
        FilterProgram,
        FilterToko,
        DownloadExcel,
        Form,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'Kode Program',
                    dataIndex: 'program_code',
                },
                {
                    title: 'Nama Program',
                    dataIndex: 'program_name',
                },
                {
                    title: 'Poin',
                    dataIndex: 'points',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Nama Reward',
                    dataIndex: 'reward_name',
                },
                {
                    title: 'Deskripsi',
                    dataIndex: 'description',
                },
                {
                    title: 'Kuartal',
                    dataIndex: 'quartal',
                },
                {
                    title: 'UOM Program',
                    dataIndex: 'uom_name',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
            ],
            endpoint: '/api/loyalty-reward/approval-list',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isApprove: false,
            isReject: false,
            params: {
                toko: [],
                program: [],
                status: null,
                page: 1,
                "per-page": 10,
            },
            selectedRows: [],
            selectedRowKeys: [],
            statusList: [
                {
                    label: "Pending Redeem",
                    value: '5',
                },
                {
                    label: "Approval Redeem",
                    value: '6',
                },
                {
                    label: "Reject Redeem",
                    value: '10',
                },
            ],
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const onDisabledCheckbox = (record) => {
            return {
                disabled: record.status_pending,
            }
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: {
                        ...pickBy(state.params),
                    },
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const sendPost = (field, status = 0) => {
            let data = {
                approval_status: status,
                allData: false,
                filters: state.params,
            }

            let rowData = []
            if (field.key == 'checked') {
                state.selectedRows.forEach((item, index) => {
                    rowData.push({
                        reward_exchange_id: item.reward_exchange_id,
                        history_id: item.history_id,
                        reference_id: item.reference_id,
                        vendor_code: item.vendor_code,
                        program_id: item.program_id,
                        reward_id: item.reward_id,
                        uom_id: item.uom_id,
                        point_status_id: item.point_status_id,
                    })
                });

                data = {
                    data: rowData,
                }
            } else {
                data.allData = true
            }

            if (state.formStatus.approval_status) {
                state.isApprove = true
            } else {
                state.isReject = true
            }

            apiClient.post('/api/loyalty-reward/approval', data)
                .then(() => {
                    state.selectedRowKeys = []
                    state.formStatus.note = null
                    fetchDataList()
                    message.success('Berhasil mengirim status')
                })
                .catch(e => message.error('Gagal mengirim status!'))
                .finally(() => {
                    state.isApprove = false
                    state.isReject = false
                    visibleRejectModal.value = false
                })
        }

        const btnApprove = (field) => {
            if (state.selectedRowKeys.length === 0 && field.key == 'checked') {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah Anda ingin approve?',
                onOk() {
                    sendPost(field, 1)
                },
                onCancel() { },
            })
        }

        const btnReject = (field) => {
            if (state.selectedRowKeys.length === 0 && field.key == 'checked') {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Reject',
                content: 'Apakah Anda ingin reject?',
                onOk() {
                    sendPost(field)
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            handleTableChange,
            onSelectChange,
            onDisabledCheckbox,
            errorMessage,
            btnApprove,
            btnReject,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            // only variable role
            hasRoles,
            ROLE_SPC,
        }
    },
})
</script>
<style scoped>
.card-body {
    margin-left: 20px;
}

.button {
    margin: 0 3px;
}
</style>
